import { Component, HostListener, OnInit, ViewChild, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { filter, map, uniq, flatten } from 'lodash-es';
import { SimpleModalService } from 'ngx-simple-modal';
import { isPlatformBrowser } from '@angular/common';
import { NguCarouselConfig } from '@ngu/carousel';
import { delay } from 'rxjs/operators';
import { GuestFormService } from '../../services/guest-form.service';
import { GuestFormComponent } from '../guest-form/guest-form.component';
import { programs } from '../../../core/component/colorConfig/programClass';
import { environment } from '../../../../environments/environment';
import { ProductService } from '../../../product/services/product.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { UserService } from '../../services/user.service';
import { ResyncEnrollmentService } from '../../services/resync-enrollment.service';

@Component({
  selector: 'hos-quick-enrollment-update',
  templateUrl: './quick-enrollment-update.component.html',
  styleUrls: ['./quick-enrollment-update.component.scss'],
  providers: [GuestFormService],
})
export class QuickEnrollmentUpdateComponent implements OnInit, OnDestroy {
  pageViewName = 'enrollmentCard';

  enrollmentCardsDetails: any;

  showLoader: Boolean = false;

  showEnrollmentsCard = false;

  locationIds: Array<any> = [];

  renewedEnrollments: any = [];

  userDataToken: any;

  existingCustomerData: any;

  serviceCustomerData: any;

  showEnrollmentCardsSubData: any;

  isBrowser: Boolean = true;

  customerDetails: any;

  addressPresentIn: any;

  buttonDetails: any;

  emailExistedData: any;

  public carouselTile: NguCarouselConfig;

  guestToken: any;

  otherProducts: any;

  activeEnrollements: any;

  @ViewChild(GuestFormComponent) registerForm: any;

  enrolledProducts: any;

  enrolledServices: any;

  @HostListener('document:click')
  clickout() {
    this.guestFormService.showDropDown = false;
  }

  constructor(
    private guestFormService: GuestFormService,
    private productService: ProductService,
    private ordersService: OrdersService,
    private sessionStorageService: SessionStorageService,
    private simpleModalService: SimpleModalService,
    private resyncEnrollment: ResyncEnrollmentService,
    @Inject(PLATFORM_ID) private platformId: any,
    public globalVariables: GlobalVariables,
    private userService: UserService,
  ) {
    this.buttonDetails = {
      hasHomeOwnerCheckbox: false,
      hasTerms: false,
      submitButtonText: 'Submit to View Enrollments',
    };
  }

  ngOnInit() {
    this.carouselTile = {
      grid: { xs: 1, sm: 2, md: 4, lg: 4, all: 0 },
      slide: 3,
      speed: 400,
      point: {
        visible: false,
      },
      load: 2,
      touch: true,
      easing: 'ease',
    };
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.guestFormService.initilize();
    this.guestFormService.registerFormSubmit.subscribe(dataRes => {
      this.existingCustomerData = dataRes.data;
      this.addressPresentIn = dataRes.type;
      this.guestFormService.user.email = this.guestFormService.formEmail;
      if (dataRes.emailExistedData) {
        this.emailExistedData = dataRes.emailExistedData;
      }
      this.guestFormService.showLoader = true;
      this.guestFormService.checkSwitch(this.registerForm.registerForm.form, dataRes.case, this.existingCustomerData, this.pageViewName);
    });
    this.guestFormService.customerNumberFormSubmit.subscribe(dataRes => {
      this.existingCustomerData = dataRes.data;
      this.addressPresentIn = dataRes.type;
      if (dataRes.emailExistedData) {
        this.emailExistedData = dataRes.emailExistedData;
      }
      if (dataRes.type === 'customercrmsDB') {
        dataRes.case = 'AddressMatched';

        this.guestFormService.showEmailPopup().subscribe((email: any) => {
          if (email) {
            this.existingCustomerData.email = email;
            // this.guestFormService.user.email = email;
            this.guestFormService.showLoader = true;
            this.guestFormService.checkSwitch(this.registerForm.registerForm.form, dataRes.case, this.existingCustomerData, this.pageViewName);
          }
        });
        return;
      }
      if (dataRes.type === 'customersDB') {
        this.existingCustomerData.address = this.existingCustomerData.address[0];
        dataRes.case = 'AllMatched';
        this.existingCustomerData.email = this.guestFormService.user.email;
      }
      this.guestFormService.showLoader = true;
      this.guestFormService.checkSwitch(this.registerForm.registerForm.form, dataRes.case, this.existingCustomerData, this.pageViewName);
    });
    this.guestFormService.showEnrollmentCards.subscribe(dataRes => {
      this.serviceCustomerData = { ...this.existingCustomerData };
      this.fetchToken(this.guestFormService.user.email);
      if (Array.isArray(this.serviceCustomerData.address)) {
        this.serviceCustomerData.address = this.serviceCustomerData.address[0];
      }
      this.showEnrollmentCardsSubData = dataRes;
      this.userDataToken = dataRes.data;
      this.customerDetails = dataRes.customerDetails;
      // Call the api to sync enrollment updates from enrollmentcrms to orders collection
      this.resyncEnrollment
        .resyncEnrollment(dataRes.customerDetails.data.customerNo, this.userDataToken.user._id, this.userDataToken.authHeader)
        .subscribe(res => {
          if (res.status === 200) {
            // only when the sync is complete call the fetch Orders
            // Delay calling fetchOrders by 3 seconds only if sync is successful
            of(null)
              .pipe(delay(3000))
              .subscribe(() => {
                this.fetchOrders(dataRes);
              });
          } else {
            console.log(`data update fail from sync collections`);
          }
        });
    });
    this.globalVariables.oneTimeSuccessEvent.subscribe(() => this.fetchOrders(this.showEnrollmentCardsSubData));
    this.enrolledProducts = JSON.parse(this.sessionStorageService.getItem('enrolledProductData'));
  }

  fetchOrders(dataRes: any) {
    if (this.userDataToken.user && dataRes.customerDetails.data.customerNo) {
      let url = `?crmCustomerNumber=${dataRes.customerDetails.data.customerNo}`;
      url += '&isActive=true';
      const oracleCustId = this.serviceCustomerData.oracleCustId
        ? this.serviceCustomerData.oracleCustId
        : this.serviceCustomerData.address.oracleCustId;
      if (oracleCustId) url += `&oracleCustId=${oracleCustId}`;
      if (this.serviceCustomerData.address.custAccountId) url += `&custAccountId=${this.serviceCustomerData.address.custAccountId}`;

      this.showLoader = true;
      this.ordersService.getOrderByAddress(this.userDataToken.user._id, url, this.userDataToken.authHeader).subscribe(
        async data => {
          if (data.status === 200) {
            this.enrollmentCardsDetails = data.body;
            this.activeEnrollements = data.body;
            this.showLoader = false;
            this.showEnrollmentsCard = true;
            this.locationIds = [];
            for (let i = 0; i < this.enrollmentCardsDetails.length; i++) {
              const objData = programs[this.enrollmentCardsDetails[i].productDetails.program.programCode];
              // this.enrollmentCardsDetails[i].colorConfigClass = objData.class;
              this.enrollmentCardsDetails[i].colorConfigStyle = objData.color;
              if (this.enrollmentCardsDetails[i].productDetails.detailHeaderBgColor) {
                this.enrollmentCardsDetails[i].colorConfigStyle = this.enrollmentCardsDetails[i].productDetails.detailHeaderBgColor;
              }
              if (this.enrollmentCardsDetails[i].renewalDate) {
                const date1: any = new Date();
                const date2: any = new Date(this.enrollmentCardsDetails[i].renewalDate);
                const oneday = 1000 * 60 * 60 * 24;
                const expiredIn: any = date2.getTime() - date1.getTime();
                const expiredInDays: any = Math.round(expiredIn / oneday);
                this.enrollmentCardsDetails[i].renewNow = false;
                this.enrollmentCardsDetails[i].showExpireDate = false;
                /* Removing handling renewals during 30 days after expiration grace period - AP (As per comment on JIRA) - Feb 18 */
                if (expiredIn <= environment.renewExpirationTimePos && expiredIn > 0) {
                  this.enrollmentCardsDetails[i].renewNow = true;
                  this.enrollmentCardsDetails[i].showExpireDate = true;
                }
                this.enrollmentCardsDetails[i].expiredIn = expiredInDays;
              }
              if (this.locationIds.indexOf(this.enrollmentCardsDetails[i].locationId) === -1) {
                this.locationIds.push(this.enrollmentCardsDetails[i].locationId);
              }
            }
            /* Changes For HWR-2168 Renew Now Button For already enrolled */
            const effDateFilter = filter(this.enrollmentCardsDetails, el => {
              const effDate: any = new Date(el.programEffectiveDate);
              const today = new Date();
              if (effDate.getTime() > today.getTime()) {
                return el;
              }
            });
            this.renewedEnrollments = map(effDateFilter, o => o.productDetails.program.programCode);
            for (let i = 0; i < this.enrollmentCardsDetails.length; i++) {
              if (this.renewedEnrollments.includes(this.enrollmentCardsDetails[i].productDetails.program.programCode)) {
                this.enrollmentCardsDetails[i].isAlreadyEnrolled = true;
              } else {
                this.enrollmentCardsDetails[i].isAlreadyEnrolled = false;
              }
            }
            const productIds = [];
            for (let i = 0; i < this.locationIds.length; i++) {
              productIds.push(this.productService.getProviderContent(this.locationIds[i]));
            }
            forkJoin(productIds).subscribe((productsData: any) => {
              for (let i = 0; i < productsData.length; i++) {
                for (let j = 0; j < this.enrollmentCardsDetails.length; j++) {
                  if (productsData[i].locationCodeId === this.enrollmentCardsDetails[j].locationId) {
                    if (productsData[i].websitePartnerName !== '') {
                      this.enrollmentCardsDetails[j].providerIcon = productsData[i].providerIcon;
                    }
                  }
                }
              }
            });
            this.sessionStorageService.setItem('enrolledProductData', JSON.stringify(this.enrollmentCardsDetails));
            this.enrolledServices = this.enrollmentCardsDetails;
            this.enrollmentCardsDetails = this.filterRenewalAndSuspendedEnr(this.enrollmentCardsDetails);
            this.getOtherProducts();
          }
          this.showLoader = false;
        },
        () => {
          this.showLoader = false;
          // TODO error pop up , does not have any enrollments
          this.showErrorModal('We are sorry. We do not currently offer this service in your location.', '', '');
        },
      );
    }
  }

  filterRenewalAndSuspendedEnr(services) {
    return services.filter(ele => {
      if (
        ele.crmStatus === '2' &&
        ele.renewNow === true &&
        !ele.isAutoRenew &&
        ele.productDetails.program.programCode !== 'LDDP' &&
        ele.billingType === 'offbill' &&
        (!ele.isAlreadyEnrolled || this.renewedEnrollments.length === 0)
      ) {
        return true;
      }
      if (ele.syncSource === 'crm') {
        if (
          ele.crmStatus === '6' &&
          ele.productDetails.program.programCode !== 'LDDP' &&
          ele.billingType === 'offbill' &&
          ele.paymentMethod !== 'onbill'
        ) {
          if (ele.paymentData && ele.paymentData.isSuspendedPayment) {
            return false;
          }
          return true;
        }
      }
      if (ele.syncSource === 'oracle') {
        if (ele.crmStatus === '6' && ele.productDetails.program.programCode !== 'LDDP' && ele.billingType === 'offbill') {
          if (ele.paymentData && ele.paymentData.isSuspendedPayment) {
            return false;
          }
          return true;
        }
      }
      return false;
    });
  }

  showErrorModal(title: String, message: String, modalComponent: any, GAErrorId?: string) {
    this.simpleModalService
      .addModal(
        modalComponent,
        {
          title,
          message,
          confirmBtnId: GAErrorId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {});
  }

  getOtherProducts() {
    this.guestToken = this.sessionStorageService.getItem('guestToken');
    let contactType = 1;

    if (this.existingCustomerData?.address?.serviceAddress?.contactType === 'COMMERCIAL') {
      contactType = 3;
    }
    this.productService
      .getRecommendedProducts(
        this.existingCustomerData?.address?.serviceAddress?.locationCodeId,
        '',
        this.existingCustomerData?.customerId,
        contactType,
        null,
        this.guestToken,
      )
      .subscribe(
        data => {
          if (data.status === 200) {
            this.otherProducts = [];
            let resData = data.body;
            if (this.activeEnrollements?.length > 0) {
              const enrolledProductData: any = this.activeEnrollements;
              const enrolledProductList = enrolledProductData
                .filter(item => item.crmStatus === '6')
                .map(value => value.productDetails.program.products);
              const uniqEnrolledProductList = uniq(flatten(enrolledProductList));
              uniqEnrolledProductList.forEach(ele => {
                resData = resData.filter(item => !item.program.products.includes(ele));
              });
            }
            this.otherProducts = resData;
          } else if (data.status !== 200) {
            // error handling
            this.otherProducts = [];
          }
        },
        () => {
          // error handling
          this.otherProducts = [];
        },
      );
  }

  fetchToken(email: any) {
    this.userService.getGuestToken(email).subscribe(async res => {
      if (res.status === 200) {
        this.guestToken = res.body.authHeader;
        this.sessionStorageService.setItem('guestTokenUpdateEnrollment', this.guestToken);
      }
    });
  }

  ngOnDestroy() {
    this.globalVariables.showCheckoutPaymentReponsePage = false;
    this.globalVariables.isSuccessCheckoutWebRegistration = false;
    this.sessionStorageService.deleteItem('guestTokenUpdateEnrollment');
  }
}
