import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, PlatformLocation } from '@angular/common';
import { forkJoin } from 'rxjs';
/* COMPONENT */
import { filter, map, forEach, findIndex } from 'lodash-es';
import { SimpleModalService } from 'ngx-simple-modal';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { programs } from '../../../core/component/colorConfig/programClass';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
/* COMPONENT */
/* SERVICE */
import { UserService } from '../../services/user.service';
import { SeoService } from '../../../core/services/seo.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { LocationService } from '../../../home/services/location.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ProductService } from '../../../product/services/product.service';
import { DotCMSService } from '../../../core/services/dotcms.service';
/* SERVICE */
import { environment } from '../../../../environments/environment';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { DotCMSCategory } from '../../../core/model/dotcms-constants.model';
import { ResyncEnrollmentService } from '../../services/resync-enrollment.service';

declare let FullStory;
@Component({
  selector: 'hos-my-account-new-home-redesign',
  templateUrl: './my-account-new-home-redesign.component.html',
  styleUrls: ['./my-account-new-home-redesign.component.scss'],
})
export class MyAccountNewHomeRedesignComponent implements OnInit, OnDestroy {
  public carouselBanner: NguCarouselConfig;

  public carouselRecommendedService: NguCarouselConfig;

  showLoader: Boolean = false;

  user: any;

  tempCheck: any = [];

  locationIds: Array<any> = [];

  myServices: any = [];

  loader: Boolean = true;

  selectedAddress: any = {};

  locationCodeData: any;

  headerContent: any = {
    propertiesCovered: '',
  };

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'My Account',
      link: '',
      active: true,
    },
  ];

  seoConfig: SeoConfig = {
    title: 'Profile Service Line Warranties | American Water Resources',
    description: `American Water Resources offers affordable home protection programs
      that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
      in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  serviceAddresses: any;

  serviceAddressesOld: any;

  urlAddressId: any;

  addressId: any;

  selectedAddIndex: any;

  selectedEnrollAddIndex: any;

  myaccountHome: boolean;

  stories: any;

  saveMoreCont: any;

  contactUsCont: any;

  customerServiceNumber: String;

  my_account_fb_img_url: any = `../../../../img/my-account/${environment.my_account_fb_img}`;

  renewedEnrollments: any = [];

  customerIdData: any;

  isLogin: any;

  isbrowser: boolean = true;

  screenWidth: number;

  contactUsBg: any;

  authHeader: any;

  isPageChanged: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private seoService: SeoService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location,
    private locationService: LocationService,
    private sessionStorageService: SessionStorageService,
    private simpleModalService: SimpleModalService,
    private orderService: OrdersService,
    public dotCMSService: DotCMSService,
    private productService: ProductService,
    private globalVariables: GlobalVariables,
    private resyncEnrollment: ResyncEnrollmentService,
    public platformLocation: PlatformLocation,
  ) {
    this.customerServiceNumber = environment.customer_service_number;
    this.isLogin = this.route.snapshot.queryParams.isLogin;
    seoService.generateTitleTags(this.seoConfig);
    seoService.setCanonicalTags();
    this.user = this.userService.getSignedInUser();
    platformLocation.onPopState(() => {
      let url = '';
      if (this.isbrowser) {
        url = window.location.pathname;
        if (!['null', 'undefined', undefined, null, ''].includes(this.sessionStorageService.getItem('authHeader'))) {
          if (url.includes('/user/myaccount')) {
            this.router.navigate(['/user/myaccount'], {
              queryParams: {
                isLogin: true,
              },
            });
          }
        } else {
          this.userService.signOut();
          this.router.navigate(['/user/login']);
        }
      }
    });

    /* HWR-3180 - My Profile and Add Property Should Maintain same header design Start ***** */
    this.globalVariables.myAccClickFromHeader.subscribe(data => {
      this.globalVariables.myAccClicked = data;
      this.myaccountHome = !this.globalVariables.myAccClicked;
      const selectedAddress = this.sessionStorageService.getItem('selectedAddress')
        ? JSON.parse(this.sessionStorageService.getItem('selectedAddress'))
        : '';
      const locationcode = this.sessionStorageService.getItem('location_code');
      const addressId = this.sessionStorageService.getItem('addressId');
      if (selectedAddress && selectedAddress.zipcode && locationcode && addressId) {
        this.router.navigate(['/user/myaccount'], {
          queryParams: {
            zipcode: selectedAddress.zipcode,
            locationcode,
            addressId,
          },
        });
      }
      this.selectedAddIndex = this.serviceAddresses.findIndex(p => p._id === addressId);
      this.changeAddress(this.selectedAddIndex, this.serviceAddresses[this.selectedAddIndex]);
    });
    this.authHeader = this.sessionStorageService.getItem('authHeader');
    /* HWR-3180 - My Profile and Add Property Should Maintain same header design End ****** */
  }

  ngOnInit() {
    this.showLoader = false;

    this.carouselBanner = {
      grid: {
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        all: 0,
      },
      slide: 1,
      speed: 400,
      interval: {
        timing: 4000,
      },
      point: {
        visible: true,
      },
      load: 2,
      loop: true,
      touch: true,
    };

    this.carouselRecommendedService = {
      grid: {
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        all: 0,
      },
      slide: 1,
      speed: 400,
      point: {
        visible: false,
      },
      load: 2,
      touch: true,
      loop: true,
      easing: 'ease',
    };

    this.dotCMSService.getContentByCategory(DotCMSCategory.HOS_MY_ACCOUNT_CAROUSEL).subscribe(response => {
      this.stories = response.data;
    });

    this.dotCMSService.getContentByCategory(DotCMSCategory.HOS_MY_ACCOUNT_SAVE_MORE).subscribe(response => {
      this.saveMoreCont = response.data[0];
    });

    this.dotCMSService.getContentByCategory(DotCMSCategory.HOS_MYACCOUNT_CONTACT_US).subscribe(response => {
      this.contactUsCont = response.data[0];
      if (this.contactUsCont) {
        if (this.contactUsCont.content && this.contactUsCont.content.length > 70) {
          this.contactUsCont.content = `${this.contactUsCont.content.slice(0, 70)}...`;
        }
        if (this.contactUsCont.imageURL) {
          this.contactUsBg = this.dotCMSService.dotCMSImageById(this.contactUsCont.imageURL);
        } else {
          this.contactUsBg = '../../../../img/my-account/contact_us.png';
        }
      }
    });

    this.urlAddressId = this.route.snapshot.queryParams.addressId;
    if (typeof this.route.snapshot.queryParams.home !== 'undefined') {
      this.myaccountHome = this.route.snapshot.queryParams.home === 'true';
    } else {
      this.myaccountHome = !this.globalVariables.myAccClicked;
    }
    this.selectedAddIndex = 0;
    this.selectedEnrollAddIndex = 0;
    this.getCustomerAddress();
    this.globalVariables.removeProperty.subscribe(result => {
      this.showLoader = true;
      this.getCustomerAddress();
      if (this.selectedAddress.customerNo === result.customerNo) {
        this.changeAddress(0, this.serviceAddresses[0], true);
      }
    });
  }

  ngOnDestroy() {
    this.globalVariables.myAccClicked = false;
  }

  getOrders(address, isAccordionChanged?) {
    this.showLoader = true;
    this.selectedAddress = address;
    const payload = {
      zipcode: this.selectedAddress.serviceAddress.zipcode,
      location_code: this.selectedAddress.serviceAddress.locationCodeId,
    };
    const tempAdd: any = {
      rawData: '',
      zipcode: address.serviceAddress.zipcode,
    };
    this.sessionStorageService.setItem('selectedAddress', JSON.stringify(tempAdd));
    this.sessionStorageService.setItem('location_code', this.selectedAddress.serviceAddress.locationCodeId);
    this.locationService.ZipcodeAndLocation = payload;

    this.locationService.getLocationsContent(this.sessionStorageService.getItem('location_code')).subscribe(
      dataReceived => {
        this.locationCodeData = dataReceived.body;
        this.globalVariables.locationData.next(this.locationCodeData);
        this.locationService.getMicroSiteForLocation(this.locationCodeData.locationCode).subscribe(
          dataMicroSiteRecieved => {
            const dataMicroSite = dataMicroSiteRecieved.body;
            if (dataMicroSite.level === 'default') {
              if (this.sessionStorageService.getItem('providerState')) {
                this.sessionStorageService.setItem('isMicroSite', '0');
                this.sessionStorageService.deleteItem('baseUrlState');
                this.sessionStorageService.deleteItem('providerState');
                let queryString = '/user/myaccount?';
                queryString += address.serviceAddress.zipcode ? `zipcode=${address.serviceAddress.zipcode}&` : '';
                queryString += address.serviceAddress.locationCodeId ? `location_code=${address.serviceAddress.locationCodeId}&` : '';
                queryString += address._id ? `addressId=${address._id}&` : '';
                queryString += `home=${this.myaccountHome}&`;
                this.showLoader = true;
                if (!isAccordionChanged) {
                  window.location.href = queryString;
                } else {
                  this.globalVariables.isAccordionChanged = false;
                  window.location.href = queryString;
                }
              } else {
                this.location.go(
                  '/user/myaccount',
                  `?zipcode=${address.serviceAddress.zipcode}&location_code=${address.serviceAddress.locationCodeId}&addressId=${address._id}`,
                );
              }
            } else {
              this.sessionStorageService.setItem('isMicroSite', '1');
              if (dataMicroSite.site.siteName) {
                dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
              } else {
                dataMicroSite.site.siteName = '';
              }
              let providerState = '';
              providerState = this.sessionStorageService.getItem('providerState')
                ? this.sessionStorageService.getItem('providerState').toLowerCase()
                : '';
              if (providerState === dataMicroSite.site.siteName) {
                this.location.go(
                  '/user/myaccount',
                  `?zipcode=${address.serviceAddress.zipcode}&location_code=${address.serviceAddress.locationCodeId}&addressId=${address._id}`,
                );
              } else {
                if (dataMicroSite.site.siteName) {
                  dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
                } else {
                  dataMicroSite.site.siteName = '';
                }
                this.sessionStorageService.setItem('providerState', dataMicroSite.site.siteName);
                this.sessionStorageService.setItem('baseUrlState', dataMicroSite.site.siteName);
                let queryString = dataMicroSite.site.siteName ? `/${dataMicroSite.site.siteName}/user/myaccount?` : '/user/myaccount?';
                queryString += address.serviceAddress.zipcode ? `zipcode=${address.serviceAddress.zipcode}&` : '';
                queryString += address.serviceAddress.locationCodeId ? `location_code=${address.serviceAddress.locationCodeId}&` : '';
                queryString += address._id ? `addressId=${address._id}&` : '';
                queryString += `home=${this.myaccountHome}&`;
                this.showLoader = true;
                if (!isAccordionChanged) {
                  window.location.href = queryString;
                } else {
                  this.globalVariables.isAccordionChanged = false;
                  window.location.href = queryString;
                }
              }
            }
          },
          () => {},
        );
      },
      () => {},
    );

    if (this.user) {
      // HWR-1210 : removed address from API as a way for perticular customer's order
      /* let url = '';
      Object.keys(address.serviceAddress).forEach(query => {
        if (arry.indexOf(query) > -1 && url === '') {
          url = `${url}?${query}=${address.serviceAddress[query]}`;
        } else if (arry.indexOf(query) > -1 && url !== '') {
          url = `${url}&${query}=${address.serviceAddress[query]}`;
        }

      }); */
      let url = `?crmCustomerNumber=${this.selectedAddress.customerNo}`;
      url += '&isActive=true';
      if (this.selectedAddress.oracleCustId) url += `&oracleCustId=${this.selectedAddress.oracleCustId}`;
      if (this.selectedAddress.custAccountId) url += `&custAccountId=${this.selectedAddress.custAccountId}`;

      if (typeof FullStory === 'function') {
        FullStory.identify(this.selectedAddress.customerNo, {
          displayName: this.selectedAddress.customerNo,
          name: `${this.selectedAddress.firstName} ${this.selectedAddress.lastName}`,
          email: this.user.user.email,
        });
      }

      if (this.isPageChanged || isAccordionChanged || !this.myServices?.length) {
        if (!this.myaccountHome) {
          this.showLoader = true;
        } else {
          this.showLoader = false;
        }
        this.orderService.getOrderByAddress(this.user.user._id, url).subscribe(
          async data => {
            if (data.status === 200) {
              this.myServices = data.body;
              this.locationIds = [];
              for (let i = 0; i < this.myServices.length; i++) {
                const objData = programs[this.myServices[i].productDetails.program.programCode];
                // this.myServices[i].colorConfigClass = objData.class;
                this.myServices[i].colorConfigStyle = objData.color;
                if (this.myServices[i].productDetails.detailHeaderBgColor) {
                  this.myServices[i].colorConfigStyle = this.myServices[i].productDetails.detailHeaderBgColor;
                }
                if (this.myServices[i].renewalDate) {
                  const date1: any = new Date();
                  const date2: any = new Date(this.myServices[i].renewalDate);
                  const oneday = 1000 * 60 * 60 * 24;
                  const expiredIn: any = date2.getTime() - date1.getTime();
                  const expiredInDays: any = Math.round(expiredIn / oneday);
                  this.myServices[i].renewNow = false;
                  this.myServices[i].showExpireDate = false;
                  /* Removing handling renewals during 30 days after expiration grace period - AP (As per comment on JIRA) - Feb 18 */
                  if (expiredIn <= environment.renewExpirationTimePos && expiredIn > 0) {
                    this.myServices[i].renewNow = true;
                    this.myServices[i].showExpireDate = true;
                  }
                  this.myServices[i].expiredIn = expiredInDays;
                }
                if (this.locationIds.indexOf(this.myServices[i].locationId) === -1) {
                  this.locationIds.push(this.myServices[i].locationId);
                }
              }
              /* Changes For HWR-2168 Renew Now Button For already enrolled */

              const effDateFilter = filter(this.myServices, el => {
                const effDate: any = new Date(el.programEffectiveDate);
                const today = new Date();
                if (effDate.getTime() > today.getTime()) {
                  return el;
                }
              });
              this.renewedEnrollments = map(effDateFilter, o => o.productDetails.program.programCode);

              for (let i = 0; i < this.myServices.length; i++) {
                if (this.renewedEnrollments.includes(this.myServices[i].productDetails.program.programCode)) {
                  this.myServices[i].isAlreadyEnrolled = true;
                } else {
                  this.myServices[i].isAlreadyEnrolled = false;
                }
              }
              /* for (let i = 0; i < this.myServices.length; i++) {
                let one_day = 1000 * 60 * 60 * 24;
                for (let j = 0; j < this.myServices.length; j++) {
                    if (this.myServices[i].productDetails.program.programCode === this.myServices[j].productDetails.program.programCode)
                    {
                        let date1: any = new Date(this.myServices[i].renewalDate);
                        let date2: any = new Date(this.myServices[j].renewalDate);
                        let calcDays: any = ((date1.getTime() - date2.getTime()) / one_day);
                        const isLeap: boolean = this.isLeapYear(date2.getFullYear());
                        if (calcDays === environment.one_year || (isLeap && calcDays === environment.one_leap_year )) {
                         this.myServices[j].renewNow = false;
                        }
                    }
                }
            } */

              const productIds = [];
              for (let i = 0; i < this.locationIds.length; i++) {
                productIds.push(this.productService.getProviderContent(this.locationIds[i]));
              }
              forkJoin(productIds).subscribe((productsData: any) => {
                for (let i = 0; i < productsData.length; i++) {
                  for (let j = 0; j < this.myServices.length; j++) {
                    if (productsData[i].locationCodeId === this.myServices[j].locationId) {
                      if (productsData[i].websitePartnerName !== '') {
                        this.myServices[j].providerIcon = productsData[i].providerIcon;
                      }
                    }
                  }
                }
              });

              this.sessionStorageService.setItem('enrolledProductData', JSON.stringify(this.myServices));
            }
            this.showLoader = false;
          },
          () => {
            this.showLoader = false;
            const alertData = {
              title: 'Error',
              message: 'Opps.. Something went wrong. Please try again later.',
              alertIcon: 'fa fa-info-circle red',
              cancelText: 'Cancel',
              confirmText: 'Ok',
            };
            this.showAlertModal(alertData);
          },
        );
      } else {
        this.showLoader = false;
      }
    }
  }

  allAddressResponse(allAddress) {
    this.headerContent.propertiesCovered = allAddress.length;
  }

  showAlertModal(data?: any, done?) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: data.title,
          message: data.message,
          alertIcon: data.alertIcon,
          cancelText: data.cancelText,
          confirmText: data.confirmText,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          if (done) {
            done(false);
          }
        }
      });
  }

  getCustomerAddress() {
    this.userService.getAllCustomerAddresses(this.user.user._id).subscribe(data => {
      this.serviceAddresses = data.body;
      forEach(this.serviceAddresses, value => {
        value.serviceAddress.city = this.allTitleCase(value.serviceAddress.city);
        value.serviceAddress.streetName = this.allTitleCase(value.serviceAddress.streetName);
        value.serviceAddress.streetNumber = this.allTitleCase(value.serviceAddress.streetNumber);
      });
      this.serviceAddressesOld = data;
      /* HWR-2655 Resync enrollments once user logged in start */
      if (this.isLogin === 'true') {
        const resultData = data.body;
        const customernumber = resultData[0].customerNo;
        this.resyncEnrollment.resyncEnrollment(customernumber, this.user.user._id, this.authHeader).subscribe(
          res => {
            if (res.status === 200) {
              console.log(`Resync enrollments success`);
            }
          },
          error => {
            console.log(error);
            this.showLoader = false;
          },
        );
        this.globalVariables.loggedInCustNotification.emit(true);
        const addressIdData: any = this.sessionStorageService.getItem('addressId');
        if (this.serviceAddresses && this.serviceAddresses.length > 0 && !['null', 'undefined', undefined, null, ''].includes(addressIdData)) {
          const addressIdIndex = this.serviceAddresses.some((el, index) => {
            if (el._id === addressIdData) {
              return index;
            }
          });
          if (addressIdIndex) {
            this.sessionStorageService.setItem('addressId', this.serviceAddresses[addressIdIndex]._id);
          } else {
            this.sessionStorageService.setItem('addressId', this.serviceAddresses[0]._id);
          }
        } else if (['null', 'undefined', undefined, null, ''].includes(addressIdData)) {
          this.sessionStorageService.setItem('addressId', this.serviceAddresses[0]._id);
        }
      }
      /* HWR-2655 Resync enrollments once user logged in end */
      if (this.serviceAddresses.length > 0) {
        let getSelectedAddressIndex = findIndex(this.serviceAddresses, {
          _id: this.urlAddressId,
        });
        if (getSelectedAddressIndex > -1) {
          this.changeAddress(getSelectedAddressIndex, this.serviceAddresses[getSelectedAddressIndex]);
          this.sendAllAddress(this.serviceAddresses);
        } else {
          this.addressId = this.sessionStorageService.getItem('addressId');
          if (this.addressId) {
            getSelectedAddressIndex = findIndex(this.serviceAddresses, {
              _id: this.addressId,
            });
            this.changeAddress(getSelectedAddressIndex, this.serviceAddresses[getSelectedAddressIndex]);
            this.sendAllAddress(this.serviceAddresses);
          } else {
            this.changeAddress(0, this.serviceAddresses[0]);
            this.sendAllAddress(this.serviceAddresses);
          }
        }
      }
    });
  }

  allTitleCase(inStr) {
    return inStr.replace(/\w\S*/g, tStr => tStr.charAt(0).toUpperCase() + tStr.substr(1).toLowerCase());
  }

  changeAddress(index, address, isAccordionChanged?) {
    this.showLoader = true;
    this.sessionStorageService.setItem('addressId', address._id);
    /* HWR-1445 : Store providerSelectionDetails if customer has already confirmed the provider */
    if (![undefined, 'undefined', null, 'null', ''].includes(address.providerSelected)) {
      const providerDetails: any = {};
      providerDetails.zipCode = address.serviceAddress.zipcode;
      providerDetails.providerSelected = true;
      this.sessionStorageService.setItem('providerSelectionDetails', JSON.stringify(providerDetails));
    } else {
      const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
        ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
        : '';
      if (providerSelectionDetails && providerSelectionDetails.zipCode !== address.serviceAddress.zipcode) {
        this.sessionStorageService.setItem('providerSelectionDetails', '{}');
      }
    }
    /* HWR-1445 end */
    this.sessionStorageService.setItem('customerGuid', address.customerGuid);
    this.customerIdData = address.customerGuid;
    if (!this.myaccountHome && this.selectedAddIndex !== index) {
      this.globalVariables.customerGuid.next(this.customerIdData);
    }
    this.selectedAddress = index;
    this.selectedAddIndex = index;
    this.selectedEnrollAddIndex = index;
    this.getOrders(address, isAccordionChanged);
    this.globalVariables.selected_Customer_number = address.customerNo;
    // this.selectAddress.emit(address);
  }

  sendAllAddress(allAddress) {
    this.allAddressResponse(allAddress);
    // this.allAddresses.emit(allAddress);
    // HWR-2517
    this.sessionStorageService.setItem('serviceaddress', JSON.stringify(this.serviceAddresses));
  }

  goToProducts() {
    const data = this.locationService.getZipcodeAndLocation();
    if (data) {
      /* HWR-2697 Location code in params change */
      /* HWR-3122 set Product Listing Page Filters per property type - Start */
      const type =
        this.serviceAddresses && this.selectedAddress.serviceAddress.contactType
          ? this.selectedAddress.serviceAddress.contactType.toLowerCase()
          : 'residential';
      this.router.navigate([`/products/${data.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/${type}`]);
      /* HWR-3122 set Product Listing Page Filters per property type - End */
    }
  }

  pageSwitch() {
    this.isPageChanged = false;
    this.myaccountHome = !this.myaccountHome;
    if (this.myaccountHome) {
      this.changeAddress(this.selectedAddIndex, this.serviceAddresses[this.selectedAddIndex]);
    } else {
      this.changeAddress(this.selectedEnrollAddIndex, this.serviceAddresses[this.selectedEnrollAddIndex]);
    }
  }

  addressHandler(event) {
    if (event) {
      this.productService.getMarketingCodes().subscribe(
        data => {
          if (data.status === 200) {
            this.locationService.getLocationsContent(event.location_code || this.sessionStorageService.getItem('location_code')).subscribe(
              dataReceived => {
                this.locationCodeData = dataReceived.body;
                this.globalVariables.locationData.next(this.locationCodeData);
                this.locationService.getMicroSiteForLocation(this.locationCodeData.locationCode).subscribe(
                  dataMicroSiteResponse => {
                    const dataMicroSite = dataMicroSiteResponse.body;
                    if (dataMicroSite.level === 'default') {
                      if (this.sessionStorageService.getItem('providerState')) {
                        this.sessionStorageService.setItem('isMicroSite', '0');
                        this.sessionStorageService.deleteItem('baseUrlState');
                        this.sessionStorageService.deleteItem('providerState');
                        window.location.href = `/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                      } else {
                        this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
                      }
                    } else {
                      this.sessionStorageService.setItem('isMicroSite', '1');
                      if (dataMicroSite.site.siteName) {
                        dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
                      } else {
                        dataMicroSite.site.siteName = '';
                      }

                      let providerState = '';
                      providerState = this.sessionStorageService.getItem('providerState')
                        ? this.sessionStorageService.getItem('providerState').toLowerCase()
                        : '';
                      if (providerState === dataMicroSite.site.siteName) {
                        this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
                      } else {
                        if (dataMicroSite.site.siteName) {
                          dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
                        } else {
                          dataMicroSite.site.siteName = '';
                        }
                        this.sessionStorageService.setItem('providerState', dataMicroSite.site.siteName);
                        this.sessionStorageService.setItem('baseUrlState', dataMicroSite.site.siteName);
                        window.location.href = `/${dataMicroSite.site.siteName}/products/${
                          event.zipcode
                        }/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                      }
                    }
                  },
                  () => {},
                );
              },
              () => {},
            );
          } else if (data.status !== 200) {
            this.showErrorModal('Error', 'No service available in your vicinity.');
          }
        },
        () => {
          this.simpleModalService.addModal(
            ErrorModalComponent,
            {
              title: 'We are sorry. We do not currently offer services in your location.',
              message: 'Please check back soon for product and service availability in your area.',
              showButton: 'close',
              alertType: 'info-type',
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: true,
            },
          );
        },
      );
    } else {
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(''));
    }
  }

  showErrorModal(title: string, message: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  changeAddressFunctionCall(data: any) {
    this.changeAddress(data.index, data.address, data.isAccordionChanged);
  }
}
